.coming-soon, .home, .create{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    overflow-y: auto;
}
.coming-soon{
    color: #888;
}

.create {
    // flex-direction: column;
    display: block;
    h2{
        margin: 5vh 0 1vh 0;
    }
    .btn {
        margin-top: 20px;
    }
    .app-types{
        padding-top:5vh;
        .app-type{
            display: flex;
            .wrapper{
                height:100%;
                flex-grow: 1;
                padding: 4vh 10px;
                border: 1px solid white;
                width:100%;
                display: flex;
                flex-direction: column;
                &> svg {
                    color:gray;
                    
                    height:3vh;
                }
                h3{
                    margin: 1vh 0;
                }
                p: {
                    border: 1px solid red;
                }
                &:hover{
                    border-color:#eee;
                    background-color: #fcfcfc;
                }
            }
        }
    }
}


.flow-editor {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: calc(100vh - 61px);
    background-color: #f7f7f7;
    overflow: hidden;


    .toolbar {
        justify-content: space-between;
        display: flex;
        line-height: 40px;
        padding: 15px;
        background-color: #f7f7f7;

        .muted {
            color: #888
        }
    }

    .main {
        display: flex;
        flex-grow: 1;
        height: calc(100vh - 131px);

        .sidebar {
            width: 300px;
            overflow-y: auto;
            padding-right: 15px;
    
            h5 {
                // border-bottom: 1px solid #eee;
                color: #888;
                padding: 5px 10px;
            }
            a[role=button]{
                float:right;
            }
            a:not([role=button]) {
                display: block;
                padding:5px 10px 5px 15px;
                text-decoration: none;
                color:#777;
                &.active{
                    color:#222;
                }
                &:hover:not(.active){
                    color:#555;
                }
            }
    
        }

        .worksheet {
            border-top-left-radius: 10px;
            border-color: #f7f7f7;
            padding: 10px 20px;
            margin-right: 20px !important;
        }

        .worksheet,
        .preview {
            // height: calc(100vh - 80px);
            overflow-y: auto;
            flex-grow: 1;
            background-color: white;
        }

        .preview {
            width: 40%;
            max-width: 450px;
            // border: 1px solid #eee;
            // padding: 0 10px;
            margin-left: 10px;
            display: flex;
        }
    }
}

.worksheet {
    textarea.h2 {
        font-size: 2rem;
        resize: none;
    }

    textarea {
        // min-height: 50px;
        border: none;
        /* Remove borders */
        box-shadow: none;
        /* Remove any shadows */
        outline: none;
        /* Remove focus outline (default glow) */
        background: none;
        padding: 5px;

        &:focus {
            border: none !important;
            box-shadow: none !important;
            outline: none !important;
            background: none !important;
        }
    }
}

.step-wrapper {
    margin: 5px 0;
    // border: 1px solid #eee;
    // padding: 5px;

    .step-toolbar {
        float: left;
        position: relative;

        margin: -30px 0 0 0;
        display: none;
        border: 1px solid #eee;
        z-index: 999;
    }
    &:hover,
    &:has(:active, :focus) {
        background-color: #f9f9f9;
        .step-toolbar {
            display: block;
        }
    }

}


.step-Comment {
    border-left:5px solid #eee;
    background-color: #fafafa;
    
    padding-left:10px;
    margin-left: 15px;
    textarea{
        color:#777;
    }
}

.align-right {
    float: right;
}