html,
body {
    height: 100%;
    overflow: hidden;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100vh; // Full height of the viewport
    overflow:auto;

    #navbar {
        display: flex;
        padding: 10px 20px;
        border-bottom: 1px solid #f0f0f0;

        .logo {
            img {
                height: 40px;

            }

            margin:0 50px 0 0;
        }

        .nav {
            display: flex;
            align-items: center;
            flex-grow: 1;

            .nav-link {
                margin: 0 10px;
                color: #333;
                text-decoration: none;

                cursor: pointer;

                &:hover {
                    color: #777;
                }
            }
        }

        .menu {}
    }

    #sdb {
        width: 250px; // Fixed width for sidebar
        display: flex;
        flex-direction: column;
        border-right: 1px solid #f0f0f0;

        &>.accordion {
            flex-grow: 1; // Fills remaining space
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            .expanded {
                flex-grow: 1;
            }

            .accordion-button:not(.collapsed) {
                background-color: transparent !important;
                border: none !important
            }
        }
    }
}

#content {
    flex-grow: 1;    
}