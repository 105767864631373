.chatbox-app{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    
    .messages {
        display: flex;
        flex-grow: 1;
        overflow-y: auto;

        scrollbar-width: 'thin';
        scrollbar-color: '#ddd #efefef';

        .messages-wrapper{
            width:100%;
            padding: 20px 0
        }
        .message{
            display: block !important;
            margin: 7px 0
        }
        .role-user{
            text-align: right;
            .message-container{
                padding:5px 10px;
                border-radius: 5px;
                background-color:#f5f5f5;
                border: 1px solid #f0f0f0;
                display:inline-block;
                max-width:70%;
                text-align: left;
            }
        }
    }
    .controls {
        display: flex;
        padding: 10px;
        button {
            padding:0 10px
        }
    }
}